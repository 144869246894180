import type { SearchState } from '@faststore/sdk'
import {
  formatSearchState,
  parseSearchState,
  SearchProvider,
} from '@faststore/sdk'
import type {
  ClusterPageQueryQuery,
  ClusterPageQueryQueryVariables,
  SellerPageInformationResponse,
} from '@generated/graphql'
import axios from 'axios'
import type { PageProps } from 'gatsby'
import { useMemo } from 'react'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
// eslint-disable-next-line import/order
import { mark } from 'src/sdk/tests/mark'
import 'src/styles/pages/plp.scss'
import 'src/styles/pages/seller.scss'

import ProductGallery from 'src/components/sections/ProductGallery'

import { api } from '../../../store.config.js'

interface ServerDataResponse {
  banner: string
  logo: string
  shopName: string
}

type Props = PageProps<
  ClusterPageQueryQuery,
  ClusterPageQueryQueryVariables,
  unknown,
  ServerDataResponse
> & { slug: string }

const useSearchParams = (props: Props): SearchState => {
  const {
    location: { href, pathname },
    slug,
  } = props

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedFacets = [{ key: 'seller', value: String(slug) }]

  const hrefState = useMemo(() => {
    const newState = parseSearchState(
      new URL(href ?? pathname, 'http://localhost')
    )

    // In case we are in an incomplete URL
    if (newState.selectedFacets.length === 0) {
      newState.selectedFacets = selectedFacets ?? []
    }

    return formatSearchState(newState).href
  }, [href, pathname, selectedFacets])

  return useMemo(() => parseSearchState(new URL(hrefState)), [hrefState])
}

function Page(props: Props) {
  const { serverData } = props

  const { banner, logo, shopName } = serverData

  const searchParams = useSearchParams(props)

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      <div className="seller-page__container">
        <section className="seller-page__banner-logo-container">
          <img
            className="seller-page__banner"
            src={banner}
            alt={`${shopName} banner`}
          />
          <img
            className="seller-page__logo"
            src={logo}
            alt={`${shopName} logo`}
          />
        </section>

        <ProductGallery title={shopName} collectionName={shopName} useFilters />
      </div>
    </SearchProvider>
  )
}

export const getServerData = async ({
  params: { slug },
}: {
  params: Record<string, string>
}) => {
  const query = `query ($shopIds: [String]) {
    listShops(shopIds: $shopIds) @context(provider: "tatauyshop.mirakl-integrations") {
      shops {
        banner
        evaluations_count
        grade
        logo
        shipping_country
        shippings {
          shipping_type_label
          shipping_zone_label
        }
        shop_id
        shop_name
      }
    }
  }`

  try {
    const { data }: { data: SellerPageInformationResponse } = await axios.post(
      `https://${api.storeId}.myvtex.com/_v/private/graphql/v1`,
      {
        query,
        variables: {
          shopIds: [slug],
        },
      },
      {
        headers: {
          Accept: 'application/vnd.vtex.ds.v10+json',
          'Content-Type': 'application/json',
        },
      }
    )

    const {
      banner,
      logo,
      shop_name: shopName,
    } = data?.data?.listShops?.shops?.[0]

    // If not found
    if (!data) {
      return {
        status: 404,
        props: null,
        headers: {
          location: `/404/?${slug}`,
        },
      }
    }

    return {
      props: { banner, logo, shopName },
    }
  } catch (error) {
    console.error(
      'Error fetching seller information:',
      error.response?.data || error.message
    )

    return {
      status: 400,
      props: null,
      headers: {
        location: `/404/?${slug}`,
      },
    }
  }
}

Page.displayName = 'Page'
export default mark(Page)

export { Head } from 'src/components/common/Head'
